<script>
    import { onMount } from "svelte";
    import { isFuture, isPast, isWithinInterval, format, isSameDay } from "date-fns";
    import { get, map, reduce, has } from "lodash-es";
    import { stringToInterval, stringToDates, deviceTimeAsIfLocal } from "./datetime";
    import { units } from "./stores";

    import Agenda from "./Agenda.svelte";
    import ValidCalendar from "./ValidCalendar.svelte";
    import Policy from "./Policy.svelte";
    import Property from "./Property.svelte";
    import Permit from "./Permit.svelte";
    import Time from "./Time.svelte";
    import NewPermit from "./NewPermit.svelte";
    import PolicyRules from "./PolicyRules.svelte";
    

    export let policy = null;
    export let selected = null;
    export let permits = [];
    export let section = null;
    export let valid = null;
    export let date = null;


    // when agenda changes, recalculate selected time
    //$: selectedTime = selected && stringToInterval(selected, deviceTimeAsIfLocal);
    $: issue = !!get(policy, "issue.enabled");
    $: disabled = !!get(policy, "disabled.reason");
    $: hasFixedStartTime = typeof get(policy, "start.time") == "string";
    $: hasFixedEndTime = typeof get(policy, "end.time") == "string";

    let element = null;
    onMount(() => {
        element && element.scrollIntoView();
    });


</script>
<article class="policy" bind:this={element}>
    <header>
        <a href="{has(policy, "property.id") ? `/properties/${policy.property.id}` : null}">
            <Property property={get(policy, "property")} />
        </a>
        <Policy policy={policy} />
    </header>

    {#if disabled}
        <aside class="alert info closed">
            <p>{@html get(policy, "disabled.reason", "").replace(/\n|\r\n|\r/g, "<br>")}</p>
        </aside>
    {:else}
        {#if get(policy, "alert.text")}
            <aside class="alert info">
                <p>{@html get(policy, "alert.text", "").replace(/\n|\r\n|\r/g, "<br>")}</p>
            </aside>
        {/if}
        {#if get(policy, "info.text")}
            <aside class="info">
                <p>{@html get(policy, "info.text", "").replace(/\n|\r\n|\r/g, "<br>")}</p>
            </aside>
        {/if}
        {#if get(policy, "pricing.description")}
            <aside class="info pricing">
                <p>{@html get(policy, "pricing.description", "").replace(/\n|\r\n|\r/g, "<br>")}</p>
            </aside>
        {/if}
    {/if}

    {#if issue && !disabled}
        <nav>
            <ul>
                {#if valid}
                        <li><a class:current={"newpermit" === section} href="{ `/properties/${get(policy, "scope")}/amenities/${get(policy, "subject")}/permits/new?valid=${valid||""}` }">Registration</a></li>
                        <li><a class:current={ "rules" === section } href="{ `/properties/${get(policy, "scope")}/amenities/${get(policy, "subject")}/rules?valid=${valid||""}` }">Rules & Info</a></li>
                {:else}
                    <li><a class:current={!section || "policy" === section} href="{ `/properties/${get(policy, "scope")}/amenities/${get(policy, "subject")}?selected=${selected||""}` }">Availability</a></li>
                    <!-- <li><a class:current={ "schedule" === section } href="{ `/properties/${get(policy, "scope")}/amenities/${get(policy, "subject")}/schedule?selected=${selected||""}` }">Limits</a></li> -->
                    <li><a class:current={ "rules" === section } href="{ `/properties/${get(policy, "scope")}/amenities/${get(policy, "subject")}/rules?selected=${selected||""}` }">Rules & Info</a></li>
                {/if}
            </ul>
        </nav>

        {#if !section || "policy" === section}
            <section class:current={!section} id="book-{get(policy, "id")}">
                {#if permits}
                <ul class="permits">
                    {#each permits as permit}
                        <li>
                            <a href="{!!permit && permit.id ? `/p/${permit.id}`: null}">
                                <Permit permit={permit} />
                            </a>
                        </li>
                    {/each}
                </ul>
                {/if}
                {#if policy && !hasFixedStartTime}
                    <Agenda date={date} selected={selected} selectable={true} policy={policy} available={true} />
                {:else if policy && hasFixedStartTime}
                    <ValidCalendar selected={selected} policy={policy} />
                {/if}
            </section>
        {:else if "schedule" === section}
            <section class:current={ "schedule" === section } id="schedule-{get(policy, "id")}">
                {#if policy}
                    <Agenda selectable={false} policy={policy} capacity={true} />
                {/if}
            </section>
        {:else if "rules" === section}
            <section class:current={ "rules" === section } id="rules-{get(policy, "id")}">
                <PolicyRules policy={policy} />
            </section>
        {/if}
        <section class:inactive={"newpermit" !== section}>
            <NewPermit policy={policy} valid={valid} units={$units || []} />
        </section>
        
        {#if !valid}
            <footer>
                <Time value={selected} />
                <a href="{selected ? `/properties/${policy.scope}/amenities/${policy.subject}/permits/new?valid=${selected}` : null}">Continue</a>
            </footer>
        {/if}

    {:else if !disabled}
        <PolicyRules policy={policy} />
    {/if}
</article>