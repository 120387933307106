<script>
  import { capitalize } from "lodash-es";
  import EntryKeypadItem from "./EntryKeypadItem.svelte";
  import EntryRemoteItem from "./EntryRemoteItem.svelte";

  export let item = null;
  export let permit = null;

  $: console.log("item=", item);
</script>

{#if item}
  <h1>
    {[
      item.name,
      item.keypad?.code?.length && "Entry Code",
      //item.remote?.enabled && `Remote Control`,
      //capitalize(item.action),
    ]
      .filter((i) => null != i)
      .join(" • ")}
  </h1>
  <!-- {#if item.remote?.enabled}
  <h2>Remote Control</h2>
  {/if} -->
  <EntryKeypadItem item={item.keypad} />
  <EntryRemoteItem {item} {permit} />
{/if}
