<script>
    import { startOfMonth, addMonths, startOfDay, endOfDay, addDays, getDay, parseISO, isWithinInterval, areIntervalsOverlapping, addMilliseconds } from "date-fns";
    import { format, format as formatTZ, toDate, utcToZonedTime } from "date-fns-tz";
    import { query } from "./router";
    import { toMilliseconds } from "duration-fns";
    
    export let policy;
    export let selected = null;

    function toZoneISOString(local, tz) {
        //console.log(local, tz);
        //if(!tz) return local.toISOString();
        return formatTZ(local, "yyyy-MM-dd'T'HH:mm:ssxxx", { timeZone: tz });
    }

    // $: startTime = policy && policy.start.time;
    // $: endTime = policy && policy.end.time;
    $: timezone = policy && policy.timezone;

    $: validStartFuture = policy && { start: addMilliseconds(new Date(), toMilliseconds(policy.valid.min.future.min)), end: addMilliseconds(new Date(), toMilliseconds(policy.valid.min.future.max)) };
    $: validDurationMinMS = policy && toMilliseconds(policy.duration.min || policy.duration);
    $: validDurationMaxMS = policy && toMilliseconds(policy.duration.max || policy.duration);

    $: console.log("validDuration=", validDurationMinMS, validDurationMaxMS);
    $: console.log("validStartFuture=", validStartFuture);

    $: statistics = policy && policy.statistics && policy.statistics.intervals;

    $: available = statistics && Object.entries(statistics).reduce((result, [ interval, values]) => {

        const [ used, allowed ] = values.split(/[^A-Z0-9]/gi).map(v => parseInt(v));

        const localInterval = interval.split("/").reduce((interval, item, i) => {
            if(i == 0) interval.start = utcToZonedTime(new Date(item), timezone);
            if(i == 1) interval.end = utcToZonedTime(new Date(item), timezone);
            return interval;
        }, {});

        console.log("testing=", used, allowed, localInterval, interval, values);

        if(isNaN(allowed) || (allowed > 0 && used < allowed)) result.push(localInterval);

        return result;

    }, []);

    $: console.log("available=", available);

    // $: closedOrFull = statistics && Object.entries(statistics).reduce((result, [ interval, values]) => {

    //     const [ used, allowed ] = values.split(/[^A-Z0-9]/gi).map(v => parseInt(v));

    //     const localInterval = interval.split("/").reduce((interval, item, i) => {
    //         if(i == 0) interval.start = utcToZonedTime(new Date(item), timezone);
    //         if(i == 1) interval.end = utcToZonedTime(new Date(item), timezone);
    //         return interval;
    //     }, {});

    //     console.log("testing=", used, allowed, localInterval, interval, values);

    //     if(allowed <= 0 || used >= allowed) result.push(localInterval);
    //     //if(isNaN(allowed) || (allowed > 0 && used < allowed)) result.push(localInterval);

    //     return result;

    // }, []);

    // $: console.log("closedOrFull=", closedOrFull);

    let start;
    let end;
    let valid;

    $: if(!valid && available && selected && timezone) {
        const [ a, b ] = selected.split("/").map(item => utcToZonedTime(item, timezone));
        valid = validateStay(a, b);
    }


    $: console.log("valid=", valid);

    $: maxValid = valid && available && maxStay(valid.start);

    $: console.log("maxValid=", maxValid);

    $: if(valid && timezone) {
        query("selected", `${toZoneISOString(valid.start, timezone)}/${toZoneISOString(valid.end, timezone)}`);
    }

    $: calendar = policy && available && buildCalendar(policy.timezone, validStartFuture, valid, maxValid);

    $: console.log("calendar=", calendar);

    function buildCalendar(tz, validStartFuture, valid, maxValid) {
        const months = [];

        let monthStart = startOfMonth(new Date());

        while(monthStart < validStartFuture.end) {

            const monthEnd = addMonths(monthStart, 1);

            const month = {
                title: format(monthStart, "MMMM yyyy"),
                iso:`${toZoneISOString(monthStart, tz)}/${toZoneISOString(monthEnd, tz)}`,
                days: []
            };

            months.push(month);

            const monthStartDayOfWeek = getDay(monthStart);
            for(let d = 0 - monthStartDayOfWeek; d < 0; d++) {
                const item = {
                    interval:`${toZoneISOString(addDays(monthStart, d), tz)}/${toZoneISOString(addDays(monthStart, d + 1), tz)}`,
                    title: "",
                }

                month.days.push(item);
            }

            let day = monthStart;
            while(day < monthEnd) {

                const dayStart = day;
                const dayEnd = addDays(day, 1);
                const dayInterval = {
                    start:dayStart,
                    end:dayEnd,
                };

                const startValidity = minStay(dayStart);

                const item = {
                    iso:`${toZoneISOString(day, tz)}/${toZoneISOString(addDays(day, 1), tz)}`,
                    interval: dayInterval,
                    dayStart,
                    dayEnd,
                    date: dayStart,
                    startable: !!startValidity,
                    enabled: (!!startValidity) || (maxValid && areIntervalsOverlapping(dayInterval, maxValid)),
                    active: (!maxValid && areIntervalsOverlapping(dayInterval, validStartFuture)) || (maxValid && areIntervalsOverlapping(dayInterval, maxValid)),
                    validmin: !!(valid && isWithinInterval(valid.start, dayInterval)),
                    validmax: !!(valid && isWithinInterval(valid.end, dayInterval)),
                    maxvalidmin: !!(maxValid && isWithinInterval(maxValid.start, dayInterval)),
                    maxvalidmax: !!(maxValid && isWithinInterval(maxValid.end, dayInterval)),
                    selected: !!(valid && areIntervalsOverlapping(dayInterval, valid)),
                    maxvalid: !!(maxValid && areIntervalsOverlapping(dayInterval, maxValid)),
                    title: format(dayStart, "d")
                }

                month.days.push(item);

                day = addDays(day, 1);
            }

            monthStart = monthEnd;

        }


        return months;
    }

    // function calculateEnd(start, duration, end) {
    //     //console.log("calculateEnd=", start, end);
    //     if(end) {
    //         return parseISO(format(end, "yyyy-MM-dd") + "T" + endTime);
    //     } else {
    //         // pick the next available end
    //         start = parseISO(format(start, "yyyy-MM-dd") + "T" + startTime);
    //         end = parseISO(format(start, "yyyy-MM-dd") + "T" + endTime);

    //         while(end.getTime() < start.getTime() + duration) {
    //             const nextEnd = addDays(end, 1);

    //             // next end won't work because it's unavailable
    //             if(closedOrFull.find(interval => isWithinInterval(nextEnd, interval))) break;
    //             // next end is outside duration
    //             if(end.getTime() > start.getTime() && nextEnd.getTime() > start.getTime() + duration) break;

    //             // continue on testing
    //             end = nextEnd;
    //         }
    //         //console.log("about to return calced end=", end);
    //         return end.getTime() > start.getTime() ? end : null;
    //     }
    // }

    function validateStay(start, end) {

        // nothing to choose from
        if(!available || !available.length) return null;

        if(start && end) {
            // find the matching one
            const startDayInterval = { start: startOfDay(start), end: endOfDay(start) };
            const endDayInterval = { start: startOfDay(end), end: endOfDay(end) };

            // find the available interval that matches this start and end
            return available.find(item => isWithinInterval(item.start, startDayInterval) && isWithinInterval(item.end, endDayInterval));

        } else if (start) {

            // find the min stay
            return minStay(start);

        }

        return null; // no interval

    }

    function minStay(start) {

        if(!available || !available.length) return null;

        const startDayInterval = { start: startOfDay(start), end: endOfDay(start) };

        return available.reduce((result, item) => {

            if(!isWithinInterval(item.start, startDayInterval)) return result;

            // ok, matches
            if(!result) return item;

            if((item.end.getTime() - item.start.getTime()) < (result.end.getTime() - result.start.getTime())) return item; // shorter

            return result;

        }, null);

    }

    function maxStay(start) {

        if(!available || !available.length) return null;

        const startDayInterval = { start: startOfDay(start), end: endOfDay(start) };

        return available.reduce((result, item) => {

            if(!isWithinInterval(item.start, startDayInterval)) return result;

            // ok, matches
            if(!result) return item;

            if((item.end.getTime() - item.start.getTime()) > (result.end.getTime() - result.start.getTime())) return item; // shorter

            return result;

        }, null);

    }

    function pickDate(date) {

        if (start && isWithinInterval(date, valid)) {
            // reset
            start = date;
            end = null;
        } else if (start && isWithinInterval(date, maxValid)) {
            // extending
            end = date;
        } else {
            // default
            start = date;
            end = null;
        }

        valid = validateStay(start, end);

        console.log("valid=", valid);

    }

</script>

{#if calendar}
{#each calendar as month}
<time class="month" datetime={month.iso}>
    <h1>{month.title}</h1>
    {#each month.days as day}
    <time class="day" class:valid={day.selected} class:maxvalid={day.maxvalid} class:maxvalidmin={day.maxvalidmin} class:maxvalidmax={day.maxvalidmax} class:validmin={day.validmin} class:validmax={day.validmax} datetime="{day.iso}">{#if day.title != ""}{#if day.enabled}<button class:active={day.active} on:click={e => pickDate(day.date)} type="button">{day.title}</button>{:else}{day.title}{/if}{/if}</time>
    {/each}
</time>
{/each}
{:else}
<h1>Checking availability…</h1>
{/if}
