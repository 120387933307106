import { Api } from "@parkingboss/api";
import { updateItems, items } from "@parkingboss/svelte-utils";
import { iso8601Offset } from "./datetime";
import { addDays } from "date-fns";
import {
  merge,
  get,
  pick,
  map,
  has,
  each,
  find,
  every,
  unset,
  orderBy,
  debounce,
} from "lodash-es";
import { writable } from "svelte/store";
import store from "store/dist/store.modern";

function updateFor(key, value) {
  if (!key || !value) return;

  // value can be a key containing a for or the map itself?
  const mapFor = value["for"] || value;

  items.update((state) => {
    if (!state[key]) state[key] = {};
    if (!state[key]["for"]) state[key]["for"] = {};
    Object.assign(state[key]["for"], mapFor); // update or add the items by key
    return state;
  });
}

export const api = new Api({
  client: "AmenityPass",
});

//let authHeader = "";
//api.user.subscribe(auth => auth && (authHeader = `Authorization=${auth.type} ${auth.token}`));

export const auth = writable(store.get("auth"));
// write to backing store
auth.subscribe(($auth) => $auth && store.set("auth", $auth));

export function base() {
  return api.settings.apiBase + apiVersion;
}

export function viewpoint(offset) {
  return encodeURIComponent(
    iso8601Offset(new Date().getTime() + (offset || 0))
  );
}

export async function fetchCreateToken(scope, subject, password, context) {
  const formData = new FormData();
  formData.append("context", context + "");

  const res = await fetch(
    `${
      api.settings.apiBase
    }/accounts/auth/tokens?viewpoint=${new Date().toISOString()}&scope=${scope}&subject=${subject}&password=${password}&context=${context}`,
    {
      method: "POST",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);

    // store?
    json.item = get(json, ["accounts", "items", get(json, "accounts.item")]);
    unset(json, "accounts");

    // store state
    auth.update(($auth) =>
      merge(
        $auth,
        {
          [scope]: json,
          [subject]: json,
        },
        {
          [json.item.scope]: json,
          [json.subject]: json,
        }
      )
    );

    return json;
    //return await fetchAndStoreUsers(scope);
  }

  return await responseJson(res);
}

// export async function fetchAccountPermits(account, auth) {

//     const res = await fetch(`${api.settings.apiBase}/locations/${account.scope}/tenants/${account.id}/permits?viewpoint=${iso8601Offset(new Date())}&valid=${iso8601Offset(addDays(new Date(), -7))}/&Authorization=${auth.type} ${auth.token}`);
//     if(res.ok) {
//         const json = await responseJson(res);
//         json.items = merge({}, json.items, ...map(json, (value, key) => value.items)); // old style
//         return json;
//         //return await fetchAndStoreUsers(scope);
//     }

//     return await responseJson(res);

// }

export const fetchAccountPermits = debounce(
  async function (account, auth) {
    const res = await fetch(
      `${api.settings.apiBase}/locations/${account.scope}/tenants/${
        account.id
      }/permits?viewpoint=${iso8601Offset(new Date())}&valid=${iso8601Offset(
        addDays(new Date(), -7)
      )}/&Authorization=${auth.type} ${auth.token}`
    );
    if (res.ok) {
      const json = await responseJson(res);
      json.items = merge(
        {},
        json.items,
        ...map(json, (value, key) => value.items)
      ); // old style
      return json;
      //return await fetchAndStoreUsers(scope);
    }

    return await responseJson(res);
  },
  1000,
  {
    trailing: true,
    leading: true,
  }
);

export async function fetchUpdatePermitExpiration(permit, data) {
  permit = permit.id || permit;

  var query = formDataToURLSearchParams(data).toString();

  const res = await fetch(
    `${
      api.settings.apiBase
    }/permits/${permit}/expires?viewpoint=${new Date().toISOString()}&${
      query || "noformdataentries=true"
    }`,
    {
      method: "PATCH",
      body: query ? null : formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);
    await fetchAndStorePermits(permit);
    return json;
    //return await fetchAndStoreUsers(scope);
  }

  return await responseJson(res);
}

function formDataToURLSearchParams(formData) {
  if (!formData || !formData.entries) return new URLSearchParams();

  //try {

  // this should cover everything that supports iterating form data
  return new URLSearchParams([...formData.entries()]); // create an array from the entries iterator (edge 18 doesn't take an iterator)

  //let searchParams = null;
  //if(searchParams) return searchParams;

  //} catch(e) {

  const searchParams = new URLSearchParams();

  /**
   * This is all to replace this single line:
   * new URLSearchParams(formData), because Edge
   */
  const keys = [...formData.keys()];
  keys.forEach((key) => {
    /**
     * For 'checkboxes', we need to append the values to the search params
     * and not just add a comma separated string.
     */
    if (keys.filter((x) => x === key).length > 1) {
      /**
       * We grab all the values and append them in one go
       */
      formData.getAll(key).forEach((value) => {
        searchParams.append(key, value);
      });

      /**
       * Then remove all the remaining instances of the key from the
       * keys array we're looping around
       */
      keys.forEach((k, i) => {
        if (k === key) {
          keys.splice(i, 1);
        }
      });
    } else {
      // Strings are simple in comparison
      searchParams.set(key, formData.get(key));
    }
  });

  return searchParams;
}

export async function fetchCreatePermit(formData) {
  var query = formDataToURLSearchParams(formData).toString();

  const res = await fetch(
    `${
      api.settings.apiBase
    }/permits/temporary?viewpoint=${new Date().toISOString()}&${
      query || "noformdataentries=true"
    }`,
    {
      method: "POST",
      body: query ? null : formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);
    storeSinglePermit(json);
    return json;
  } else if (res.status == 402) {
  }

  return await responseJson(res);
}

function storeSinglePermit(json) {
  if (!json) return json;
  updateItems(merge({}, json.items, ...map(json, (value, key) => value.items))); // old style
  for (const [key, value] of Object.entries(json)) {
    if (!!value["for"]) updateFor(key, value);
  }
  return json;
}

export async function fetchAndStorePermits(ids) {
  if (!ids) return {};
  if (typeof ids === "string") ids = [ids];
  if (!ids || !ids.length) return {};
  const res = await fetch(
    `${api.settings.apiBase}/permits?viewpoint=${new Date().toISOString()}${ids
      .map((id) => "&permit=" + id)
      .join("")}&payments=true`
  );
  const json = await res.json();
  updateItems(json);
  for (const [key, value] of Object.entries(json)) {
    if (!!value["for"]) updateFor(key, value);
  }
  return json;
}

export async function responseJson(response) {
  if (!response) return {};
  return response
    .text()
    .then(function (text) {
      if (!text)
        return {
          status: response.status,
        };

      return Promise.resolve(text)
        .then(JSON.parse)
        .catch(function (error) {
          return {
            status: response.status,
            message: text,
          };
        });
    })
    .catch(function (error) {
      return {
        status: response.status,
      };
    });
}

export async function fetchRequestPhysicalAccess(permit, via) {
  permit = permit?.id || permit;

  if (!permit) return null;

  const res = await fetch(
    `${
      api.settings.apiBase
    }/entry/request?viewpoint=${viewpoint()}&permit=${permit}&via=${via}`,
    {
      method: "POST",
      //body: formData,
    }
  );

  const json = await responseJson(res);

  if (null == json) return json;

  return Object.values(json.physicalaccess?.results ?? {})[0];

  return json;
}
