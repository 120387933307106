<script>
  import SvgQr from "svgqr.js";
  import {
    isFuture,
    isPast,
    isWithinInterval,
    format,
    isSameDay,
  } from "date-fns";
  import Property from "./Property.svelte";
  import Policy from "./Policy.svelte";
  import Permit from "./Permit.svelte";
  import Valid from "./Valid.svelte";
  import ExpirePermit from "./ExpirePermit.svelte";
  import {
    deviceTimeAsIfLocal,
    iso8601,
    iso8601Offset,
    display,
  } from "./datetime";
  import { get } from "lodash-es";
  import NewPermit from "./NewPermit.svelte";
  import EntryItems from "./components/EntryItems.svelte";

  export let permit = null;
  export let status = null;

  //$: console.log(permit);

  $: permitSvgQr = !!permit
    ? SvgQr(`https://amenitypass.app/p/${permit.id}`.toUpperCase(), {
        correction: "M",
        mode: "Alphanumeric",
      })
    : "";
  $: serial = !!permit
    ? parseInt(permit.number).toString(16).padStart(8, "0")
    : "";
  //$: console.log(permit);
  //$: console.log(permit && permit.valid.interval);
  $: issued = !!permit && permit.issued.datetime;
  $: issuedLocal = !!permit && deviceTimeAsIfLocal(permit.issued.datetime);
  $: valid = !!permit ? permit.valid.interval.split("/") : [];
  $: validLocal = valid.map(deviceTimeAsIfLocal);
  $: cancelled = permit && permit.cancelled;
  $: fees = Object.values((permit && permit.fees && permit.fees.items) || {});
</script>

{#if permit && permit.id}
  <article class="{permit.type} {status}">
    <header>
      <h1>{permit.title}</h1>
      <a href="/properties/{permit.property.id}">
        <Property property={permit.property} />
      </a>
      <Policy policy={permit.policy} />
    </header>
    <Permit {permit} bind:status />

    <!-- <Valid interval={valid.join("/")} bind:status={status} /> -->
    {#if permit.entry}
      <EntryItems entry={permit.entry} {permit} />
    {/if}
    <aside>
      <figure class="validate">
        {@html permitSvgQr}
        <figcaption>
          {#each serial as char}
            <span>{char}</span>
          {/each}
        </figcaption>
      </figure>
      <ul>
        <li>
          <time class="valid min" datetime={valid[0]}
            ><dfn>Start </dfn>
            <time datetime={format(validLocal[0], "HH:mm:ss")}
              >{format(validLocal[0], "h:mm a")}</time
            >
            <time datetime={format(validLocal[0], "yyyy-MM-dd")}
              >{format(validLocal[0], "EE MMM d yyyy")}</time
            ></time
          >
        </li>
        <li>
          <time class="valid max" datetime={valid[1]}
            ><dfn>End </dfn>
            <time datetime={format(validLocal[1], "HH:mm:ss")}
              >{format(validLocal[1], "h:mm a")}</time
            >
            <time datetime={format(validLocal[1], "yyyy-MM-dd")}
              >{format(validLocal[1], "EE MMM d yyyy")}</time
            ></time
          >
        </li>
        <li>
          <time class="issued" datetime={issued}
            ><dfn>Registered </dfn>
            <time datetime={format(issuedLocal, "HH:mm:ss")}
              >{format(issuedLocal, "h:mm a")}</time
            >
            <time datetime={format(issuedLocal, "yyyy-MM-dd")}
              >{format(issuedLocal, "EE MMM d yyyy")}</time
            ></time
          >
        </li>
      </ul>
    </aside>

    {#if fees && fees.length}
      <ul class="fees">
        {#each fees as fee}
          <li>
            <h1>{fee.name}</h1>
            <data value={fee.total.value}>{fee.total.display}</data>
            <ul>
              {#each Object.values(fee.payments?.items || fee.payments || []) as payment}
                <li>
                  {#if payment.captured}
                    <data class="card" value={payment.card.type}
                      >{payment.card.last4}</data
                    > <time datetime={payment.captured.datetime}>Paid</time>
                  {:else if cancelled}
                    <data class="card" value={payment.card.type}
                      >{payment.card.last4}</data
                    > <time datetime="">Cancelled</time>
                  {:else if payment.authorized && payment.capture && payment.capture.explicitly}
                    <data class="card" value={payment.card.type}
                      >{payment.card.last4}</data
                    >
                    <time datetime={payment.authorized.datetime}>Pending</time>
                  {:else if payment.authorized}
                    <data class="card" value={payment.card.type}
                      >{payment.card.last4}</data
                    > <time datetime={payment.authorized.datetime}>Paid</time>
                  {:else}
                    <data class="card" value={payment.card.type}
                      >{payment.card.last4}</data
                    > <time datetime={payment.created}>Pending</time>
                  {/if}
                </li>
              {/each}
            </ul>
          </li>
        {/each}
      </ul>
    {/if}
    <footer>
      <p>
        Valid for listed {!!get(permit, "contact.name")
          ? "person"
          : get(permit, "tenant.format")} only. Please be prepared to show pass as
        required to {get(permit, "contact.name") ? "occupants and" : ""} authorized
        personnel. May not be cancelled after the start time.
      </p>
      <ExpirePermit {permit} />
    </footer>
  </article>
{/if}
