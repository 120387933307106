import { writable, derived, readable } from "svelte/store";
import { items, updateItems, geoLocation } from "@parkingboss/svelte-utils";
import {
  filter,
  merge,
  get,
  pick,
  map,
  has,
  each,
  find,
  every,
  unset,
  orderBy,
  debounce,
  pickBy,
} from "lodash-es";
import {
  format,
  startOfDay,
  endOfDay,
  addHours,
  startOfHour,
  addDays,
} from "date-fns";
import {
  api,
  responseJson,
  fetchAndStorePermits,
  fetchAccountPermits,
  auth,
} from "./api";
import { addMinutes } from "date-fns";
import { client as mqtt } from "./mqtt";
import store from "store/dist/store.modern";
import { params } from "./params";

function updateFor(key, value) {
  if (!key || !value) return;

  // value can be a key containing a for or the map itself?
  const mapFor = value["for"] || value;

  items.update((state) => {
    if (!state[key]) state[key] = {};
    if (!state[key]["for"]) state[key]["for"] = {};
    Object.assign(state[key]["for"], mapFor); // update or add the items by key
    return state;
  });
}

export { params };

const comparer =
  !!window.Intl && !!window.Intl.Collator
    ? new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base",
        caseFirst: "lower",
      }).compare
    : (a, b) => (a < b ? -1 : a > b ? 1 : 0);

export const minuteNow = readable(new Date(), (set) => {
  set(new Date());
  const i = setInterval(() => set(new Date()), 60 * 1000);
  return () => clearInterval(i);
});
export const secondNow = readable(new Date(), (set) => {
  set(new Date());
  const i = setInterval(() => set(new Date()), 1000);
  return () => clearInterval(i);
});

export const timeStore = function (seconds) {
  return readable(new Date(), (set) => {
    const i = setInterval(() => {
      set(new Date());
    }, seconds * 1000);
    return () => clearInterval(i);
  });
};

//export const state = writable({});

export const state = items;

export const tenantId = writable(null);
export const spaceId = writable(null);

export const policyId = derived(
  [params],
  ([params]) => params.policy && params.policy.toLowerCase()
);
export const propertyId = derived(
  [params],
  ([params]) => params.property && params.property.toLowerCase()
);
export const permitId = derived(
  [params],
  ([params]) => params.permit && params.permit.toLowerCase()
);
export const view = derived([params], ([params]) => params.view);
export const valid = derived([params], ([params]) => params.valid);
export const search = derived(
  [params],
  ([params]) => params.q || params.search
);

let validPropertyAuthId = null;
export const validPropertyAuth = derived(
  [propertyId, auth, minuteNow],
  ([$propertyId, $auth, $now], set) => {
    if (!$propertyId) return; // indeterminate

    //console.log("validate auth", $auth, $now, $propertyId);
    if (!$auth) return set(null); // no auth at all
    var item = get($auth, $propertyId);
    if (!item) return set(null);
    if (new Date(item.expires) < addMinutes($now, 1)) return set(null); // expired or gonna expire in the next minute

    //console.log("only change if different subject now...", item.subject, validPropertyAuthId);

    // only change the set if it's a different tenant id
    if (item.subject !== validPropertyAuthId) set(item);
    validPropertyAuthId = item.subject;
  }
);

export const account = derived(validPropertyAuth, ($validPropertyAuth) => {
  return $validPropertyAuth && $validPropertyAuth.item;
});

// background update watcher
export const updated = writable({});
let subscribedPropertyId = null;
const mqttConnection = mqtt(
  "AKIAIUPPRVWKBYHY4UWQ",
  "GQQeZRDLfbR9JpVeIuAJzcAOgSlaJXABCRsqR3M8",
  (json) => {
    //console.log("mqtt.message=", json);
    //updated.set(json);
    updated.set({
      scope: subscribedPropertyId,
      type: Object.keys(json)[0],
      updated: json[Object.keys(json)[0]],
    });
  }
);
propertyId.subscribe((propertyId) => {
  if (!propertyId) return;
  if (subscribedPropertyId == propertyId) return;

  // propertyId changed...

  // unsubscribe
  if (!!subscribedPropertyId)
    mqttConnection.unsubscribe(`locations/${subscribedPropertyId}`);

  // subscribe
  mqttConnection.subscribe(`locations/${(subscribedPropertyId = propertyId)}`);
});

updated.subscribe((value) => {
  if (!value || !value.scope) return;
  fetchAndStorePropertyPoliciesStatistics(value.scope);
});

const proximity = geoLocation(api);

export const geo = derived([proximity, state], ([json, state]) => {
  if (!json) return null;
  return map(json.properties.items, (value) => state[value] || value).filter(
    (i) => !!i
  );
});

const searches = {};

// export const searchProperties = derived([ search ], ([ query ], async () => {
//     if(!query) return null;
//     if(query.length < 5) return null;
//     return searches[query] || (searches[query] = fetchAndStoreProperties(query));
// });

export const searchProperties = derived(
  search,
  async (query, set) => {
    if (!query) return set(null);
    if (query.length < 4) return set(null);

    set({
      query,
      loading: true,
    });

    const results =
      searches[query] ||
      (searches[query] = (await fetchAndStoreSearchProperties(query)).filter(
        (property) => property.amenities.items.includes("amenity")
      ));

    //console.log("results=", results);

    set({
      query,
      loading: false,
      items: results,
    });
  },
  null
);

//state.subscribe(value => console.log("state.subscribe=", value));

//geo.subscribe(value => console.log("geo.subscribe=", value));

async function fetchAndStoreProperties(ids) {
  if (!ids || !ids.length) return {};
  var url =
    typeof ids === "string"
      ? `${
          api.settings.apiBase
        }/properties?viewpoint=${new Date().toISOString()}&q=${ids}`
      : `${
          api.settings.apiBase
        }/properties?viewpoint=${new Date().toISOString()}${ids
          .map((id) => "&property=" + id)
          .join("")}`;
  const res = await fetch(url);
  const json = await res.json();

  // each(get(json, "properties.items"), id => {
  //     resolveProperty(json.items[id], json.items); // expand the property first
  // })

  updateItems(json);
  return map(get(json, "properties.items"), (id) =>
    resolveProperty(json.items[id], json.items)
  ); // expand the property first
}

async function fetchAndStoreSearchProperties(query) {
  if (!query) return {};
  var url = `${
    api.settings.apiBase
  }/properties?viewpoint=${new Date().toISOString()}&q=${query}`;
  const res = await fetch(url);
  const json = await res.json();

  // each(get(json, "properties.items"), id => {
  //     resolveProperty(json.items[id], json.items); // expand the property first
  // })

  updateItems(json);
  return orderBy(
    map(get(json, "properties.items"), (id) =>
      resolveProperty(json.items[id], json.items)
    ),
    [(item) => get(json, ["properties", "scores", item.id], 0)],
    ["desc"]
  ); // expand the property first
}

const fetchAndStorePropertyPoliciesStatistics = debounce(
  async function (property) {
    const json = await Promise.all([
      fetch(
        `${
          api.settings.apiBase
        }/permits/policies/issue/statistics?scope=${property}&viewpoint=${new Date().toISOString()}&valid=${format(
          startOfDay(new Date()),
          "yyyy-MM-dd'T'HH:mm:ss"
        )}/&public=true&actual=false`
      ),
    ])
      .then((values) => Promise.all(values.map((res) => res.json())))
      //.then(values => (values.map(json => pick(json, "items"))))
      .then((values) => merge({}, ...values));

    each(json, (value, key) => {
      //console.log(key, value);
      if (has(value, "items")) json.items[key] = value.items;
    });

    updateItems(json);

    for (const [key, value] of Object.entries(json)) {
      if (!!value["for"]) updateFor(key, value);
    }
  },
  1000,
  {
    trailing: true,
    leading: true,
  }
);

async function fetchAndStorePropertyPolicies(id) {
  const json = await Promise.all([
    //fetch(`${api.settings.apiBase}/locations/${id}?viewpoint=${new Date().toISOString()}`),
    fetch(
      `${
        api.settings.apiBase
      }/permits/policies/issue?scope=${id}&viewpoint=${new Date().toISOString()}&valid=${format(
        startOfDay(new Date()),
        "yyyy-MM-dd'T'HH:mm:ss"
      )}/&public=true&actual=false&slots=true&pricing=policy`
    ),
    fetch(
      `${
        api.settings.apiBase
      }/units?scope=${id}&viewpoint=${new Date().toISOString()}`
    ),
    //fetch(`${api.settings.apiBase}/spaces?scope=${id}&viewpoint=${new Date().toISOString()}`)
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  each(json, (value, key) => {
    //console.log(key, value);
    if (has(value, "items")) json.items[key] = value.items;
  });

  updateItems(json);

  for (const [key, value] of Object.entries(json)) {
    if (!!value["for"]) updateFor(key, value);
  }

  //state.update(prev => merge(prev, json.items));
}

export const propertyIds = writable(store.get("properties", {}));
// write to backing store
propertyIds.subscribe(($propertyIds) => {
  //console.log("writing properties to local storage", value);
  store.set("properties", $propertyIds);
});
params.subscribe(($value) => {
  if ($value && $value.reset) propertyIds.set({});
});

export const permitIds = writable(store.get("permits", {}));
// write to backing store
permitIds.subscribe(($permitIds) => {
  store.set("permits", $permitIds);
});

let permitRefresher = null;
permitId.subscribe(async (value) => {
  if (!!permitRefresher) clearInterval(permitRefresher);

  if (!value) return;

  // permit id changed
  await fetchAndStorePermits([value]);

  permitRefresher = setInterval(() => fetchAndStorePermits([value]), 60 * 1000);

  permitIds.update((prev) =>
    merge(
      pickBy(
        prev,
        (last) => Date.now() < new Date(last).getTime() + 24 * 60 * 60 * 1000
      ),
      {
        [value]: new Date().toISOString(),
      }
    )
  );
});

let permitsRefresher = null;
permitIds.subscribe(async (value) => {
  if (!!permitsRefresher) clearInterval(permitsRefresher);

  if (!value) return null;
  var ids = map(value, (time, id) => id);
  if (!ids.length) return null;

  // permit id changed
  await fetchAndStorePermits(ids);

  permitsRefresher = setInterval(() => fetchAndStorePermits(ids), 60 * 1000);
});

// once you're on a property, assumed until the actual value changes
// store previous value and use value equality to prevent frothy requests
let propertyRefresher = null;
let previousPropertyId = null;
propertyId.subscribe(async (value) => {
  //if(!!propertyRefresher) clearInterval(propertyRefresher); // alway stop the scheduler

  if (!value) return; // don't do anything, but keep previous value cached

  if (value === previousPropertyId) return; // the assignment changed, but not the actual value;

  // changing value, reset...
  state.update((state) => {
    unset(state, "policies");
    return state;
  });

  if (!!propertyRefresher) clearInterval(propertyRefresher); // stop the previous scheduler

  previousPropertyId = value;

  //console.log("propertyId changed=", value);

  // permit id changed
  await fetchAndStorePropertyPolicies(value);
  fetchAndStorePropertyPoliciesStatistics(value);
  propertyRefresher = setInterval(
    () => fetchAndStorePropertyPoliciesStatistics(value),
    5 * 60 * 1000
  );

  //console.log("writing propertyId to list", value);
  propertyIds.update((prev) =>
    merge(prev, {
      [value]: new Date().toISOString(),
    })
  );
});

let propertiesRefresher = null;
propertyIds.subscribe(async (value) => {
  if (!!propertiesRefresher) clearInterval(propertiesRefresher);

  if (!value) return null;
  var ids = map(value, (time, id) => id);
  if (!ids.length) return null;

  // permit id changed
  await fetchAndStoreProperties(ids);

  propertiesRefresher = setInterval(
    () => fetchAndStoreProperties(ids),
    5 * 60 * 1000
  );
});

function resolveAddress(item, items) {
  if (!item) return item;
  item.address = items[item.address] || item.address;
  return item;
}

function resolveProperty(item, items) {
  if (!item) return item;
  if (typeof item === "string") item = items[item];
  return resolveAddress(item, items);
}

export const permit = derived([permitId, state], ([id, items]) => {
  if (!id) return null;

  const permit = items[id];

  if (!permit) return null;

  // merge at derive time...more to do

  return merge(permit, {
    property: resolveProperty(items[permit.location] || permit.location, items),
    address: items[permit.address] || permit.address,
    policy:
      items[permit.issued.policy] ||
      items[permit.issued.issuer] ||
      permit.issued.issuer,
    vehicle: items[permit.vehicle] || permit.vehicle,
    spaces: (permit.spaces || []).map((i) => items[i] || i),
    tenant: items[permit.tenant] || permit.tenant,
    entry: items.entry?.["for"]?.[permit.id],
    fees: get(items, ["fees", "for", permit.id]) || permit.fees || {},
    // fees: Object.values(
    //   get(items, ["fees", "for", permit.id]) || permit.fees || {}
    // ).map((fee) => {
    //   fee = items[fee] || fee;

    //   //   fee.payments = Object.values(
    //   //     get(items, ["payments", "for", fee.id]) || fee.payments || {}
    //   //   ).map((payment) => items[payment] || payment);

    //   return fee;
    // }),
  });
});
export const property = derived([propertyId, state], ([id, items]) => {
  // cleanup?
  // if(!id) {
  //     //unset(state, "policies");
  //     state.set(state);
  // }

  return resolveProperty(items[id], items);
});

export const policies = derived([property, state], ([property, state]) => {
  if (!property) return null;
  if (!state["policies"]) return null;
  var policies = map(
    state["policies"],
    (version, policy) => state[policy] || state[version]
  );
  //console.log("policies=", policies);
  if (!policies.every((item) => !!item)) return null; // not done loading

  return policies
    .filter(
      (item) =>
        !!item && item.scope === property.id && item.amenity !== "parking"
    )
    .map((item) => {
      //item.statistics = get(state, [ "statistics", item.id ]) || get(state, [ "statistics", item.subject ]);
      item.statistics =
        get(state, ["statistics", "for", item.id]) ||
        get(state, ["statistics", "for", item.subject]);
      item.pricing =
        get(state, ["pricing", "for", item.id]) ||
        get(state, ["pricing", "for", item.subject]);
      item.metered =
        get(state, ["metered", "for", item.id]) ||
        get(state, ["metered", "for", item.subject]);
      //item.meters.items = map(item.meters.items, m => state[m] || m);
      item.property = resolveProperty(item.location, state);
      return item;
    })
    .sort((a, b) => comparer(a.title, b.title));
});

export const policy = derived(
  [policyId, policies, state],
  ([id, policies, items]) => {
    var item =
      !!id &&
      !!policies &&
      policies.find((item) => item.id === id || item.subject === id);
    return item;
    return merge(item, {
      property: resolveProperty(item.location, items),
    });
  }
);

//export const permits = writable(null);

export const permits = derived(
  [validPropertyAuth, updated],
  async ([$auth], set) => {
    if (!$auth) return set(null); // hard no data

    const json = await fetchAccountPermits($auth.item, $auth);

    const items = json.items;

    //console.log("permits set=", json, items);

    const values = orderBy(
      filter(
        get(json, "permits.items", {}),
        (permit) =>
          permit &&
          permit.issued.policy &&
          permit.amenity !== "parking" &&
          !!permit.valid.max
      ).map((permit) =>
        !permit
          ? permit
          : merge(permit, {
              property: resolveProperty(
                items[permit.location] || permit.location,
                items
              ),
              address: items[permit.address] || permit.address,
              policy:
                items[permit.issued.policy] ||
                items[permit.issued.issuer] ||
                permit.issued.policy,
              vehicle: items[permit.vehicle] || permit.vehicle,
              spaces: (permit.spaces || []).map((i) => items[i] || i),
              tenant: items[permit.tenant] || permit.tenant,
            })
      ),
      ["valid.interval"],
      ["desc"]
    );

    //.filter(permit => !propertyId || propertyId === permit.property.id).filter(permit => permit.policy && permit.policy.id).filter(permit => !policyId || policyId === permit.policy.subject || policyId === permit.policy.id);

    //console.log(values);
    set(values);
  }
);

// validPropertyAuth.subscribe(async $auth => {

//     // what are we subcribing too?
//     console.log("validPropertyAuth.subscribe", $auth);

//     permits.set(null);
//     if(!$auth) return;

// });

// export const permits = derived([ propertyId, policyId, permitIds, state ], ([ propertyId, policyId, ids, items ]) => {

//     if(!ids) return null;

//     const values = map(ids, (timestamp, id) => items[id]);
//     //if(!every(values, i => !!i)) return null;

//     return values.filter(permit => permit && permit.amenity !== "parking").map(permit => !permit ? permit : merge(permit, {
//         property: resolveProperty(items[permit.location] || permit.location, items),
//         address: items[permit.address] || permit.address,
//         policy: items[permit.issued.policy] || items[permit.issued.issuer] || permit.issued.issuer,
//         vehicle: items[permit.vehicle] || permit.vehicle,
//         spaces: (permit.spaces || []).map(i => items[i] || i),
//         tenant: items[permit.tenant] || permit.tenant,
//     })).filter(permit => !propertyId || propertyId === permit.property.id).filter(permit => permit.policy && permit.policy.id).filter(permit => !policyId || policyId === permit.policy.subject || policyId === permit.policy.id);

//     // check for missing?

//     return values;

// });

export const properties = derived([propertyIds, state], ([ids, items]) => {
  if (!ids) return null;
  //console.log("properties=", ids);
  const values = orderBy(
    map(ids, (time, id) => ({ id, time })),
    ["time"],
    ["desc"]
  ).map(({ id }) => resolveProperty(items[id], items));
  //console.log("properties=", values);
  if (!values.every((item) => !!item)) return null; // not done loading

  //console.log("properties=", values);
  //if(!every(values, i => !!i)) return null;

  // check for missing?

  return values;
});

export const units = derived([property, state], ([property, state]) => {
  if (!property) return null;
  return map(state["units"], (value, key) => state[key])
    .filter((item) => !!item && item.scope === property.id)
    .sort((a, b) => comparer(a.display, b.display));
});
export const spaces = derived([property, state], ([property, items]) => {
  if (!property) return null;
  return map(items["spaces"], (value, key) => items[key]).filter(
    (item) => !!item && item.scope === property.id
  );
});
export const space = derived([spaceId, state], ([id, items]) => items[id]);
export const tenant = derived([tenantId, state], ([id, items]) => items[id]);

// on permit update selected property
policy.subscribe((policy) => {
  if (!policy) return;
  params.update((prev) =>
    merge(prev, {
      property: policy.property.id || policy.property || policy.location,
    })
  );
});

// on permit update selected property
permit.subscribe((permit) => {
  if (!permit) return;
  params.update((prev) =>
    merge(prev, {
      property: permit.location.id || permit.location,
      policy: permit.policy.id,
    })
  );
});

// loggers
//permit.subscribe(value => console.log("permit.store=", value));
//properties.subscribe(value => console.log("properties.store=", value));
// permits.subscribe(value => console.log("permits.store=", value));
// view.subscribe(value => console.log("view.store=", value));
//policy.subscribe(value => console.log("policy.store=", value));
// policies.subscribe(value => console.log("policies.store=", value));
//units.subscribe(value => console.log("units.store=", value));
