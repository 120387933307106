<script context="module">
  const now30s = timeStore(30);
</script>

<script>
  import { parseISO } from "date-fns";

  import { defer, identity, capitalize } from "lodash-es";
  import { fetchRequestPhysicalAccess } from "../api";
  import { timeStore } from "../stores";

  export let item = null;
  export let permit = null;

  let working = false;
  let result = null;
  let error = null;

  // $: gate = true == item?.barrier?.includes("gate");
  // $: door = item?.door;
  // $: parking_entrance = item?.amenity == "parking_entrance";
  // $: label = [
  //   parking_entrance && "Parking Entrance",
  //   gate && "Gate",
  //   door && (door != "yes" ? `${capitalize(door)} Door` : "Door"),
  // ]
  //   .filter(identity)
  //   .join(" ");

  $: issuedutc = permit && parseISO(permit.issued.utc);

  $: [startstr, endstr] = permit?.valid.interval.split("/") ?? ["", ""];
  $: startutc =
    startstr &&
    new Date(Math.max(parseISO(startstr), Math.min(issuedutc, Date.now())));

  $: endutc = endstr && parseISO(endstr);

  $: nowutc = permit && new Date(Math.max(Date.now(), $now30s));

  $: pending = permit && nowutc < startutc;
  $: expired = permit && (permit.expired || nowutc > endutc);
  $: active = permit && !expired && !pending;

  async function submit(e) {
    //const button = e.target;

    if (working) return;

    working = true;

    //const form = e.target;

    //console.log("callling api");

    const res = await fetchRequestPhysicalAccess(permit, e.target.value);

    //console.log("entry=", item);

    working = false;

    if (res) {
      result = res;
    } else {
      error = true;
      defer(() => alert(`There was an error ${item.action}ing`));
    }

    // sort out result

    // timer reset 5s
    setTimeout(function () {
      error = result = null;
    }, 15 * 1000);
  }
</script>

{#if item}
  {#if item.remote?.enabled}
    <form>
      <input type="hidden" name="via" value={item.subject || item.id} />
      <button
        name="via"
        value={item.subject || item.id}
        class={[item.action, item.amenity, result?.result]
          .filter(identity)
          .join(" ")}
        type="submit"
        disabled={working || pending || expired}
        on:click={submit}
        class:working
        class:error
        class:success={result}
        class:pending
        class:expired
      >
        {[
          result?.result,
          pending && "upcoming",
          expired && "expired",
          error && "error",
          //working && "connecting",
          !working && item.action,
        ].find(identity) || ""}
        <!-- <dfn>{accesspoint.action}</dfn> -->
      </button>
    </form>
  {/if}
{/if}
