import identity from "lodash-es/identity";

const pdfContentType = "application/pdf";

export function format(fileOrUrl) {
  if (!fileOrUrl) return null;
  if (fileOrUrl.local && fileOrUrl.format == pdfContentType) return null;
  if (fileOrUrl.local) return fileOrUrl.url;
  var url = new URL(fileOrUrl.url || fileOrUrl);
  var pdf = fileOrUrl.format == pdfContentType || url.pathname.includes(".pdf");
  var heic = fileOrUrl.format == "image/heic" || url.pathname.includes(".heic");
  var tiff = fileOrUrl.format == "image/tiff" || url.pathname.includes(".tiff");
  if (heic) {
    url.host = "parking-uploads.imgix.net";
    var query = url.searchParams;
    if(heic) query.append("auto", "compress,format");
    // if (width) query.append("w", width);
    // if (height) query.append("h", height);
    // if (crop) query.append("fit", "crop");
    //url.searchParams = query;
    return url.toString();
  }

  return url.toString();
}

export function thumbnail(fileOrUrl, width, height, crop) {
  //console.log("fileOrUrl", fileOrUrl);
  if (!fileOrUrl) return;
  if (fileOrUrl.local && fileOrUrl.format == pdfContentType) return null;
  if (fileOrUrl.local) return fileOrUrl.url;
  var url = new URL(fileOrUrl.url || fileOrUrl);
  var pdf = fileOrUrl.format == pdfContentType || url.pathname.includes(".pdf");
  var heic = fileOrUrl.format == "image/heic" || url.pathname.includes(".heic");
  var tiff = fileOrUrl.format == "image/tiff" || url.pathname.includes(".tiff");
  if (pdf || heic || tiff) {
    url.host = "parking-uploads.imgix.net";
    var query = url.searchParams;
    query.append("auto", "compress,format");
    if (width) query.append("w", width);
    if (height) query.append("h", height);
    if (crop) query.append("fit", "crop");
    //url.searchParams = query;
    return url.toString();
  }

  return (
    //"https://property-content.com/cdn-cgi/image/" +
    url.origin + "/cdn-cgi/image/" +
    [
      width && `w=${width}`,
      height && `h=${height}`,
      crop && `fit=cover`,
      "format=auto",
    ]
      .filter(identity)
      .join(",") +
    //"/" +
    url.pathname
  );
}
