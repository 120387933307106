<script>
    import { isFuture, isPast, isBefore, isAfter, isWithinInterval, format, isSameDay, addMinutes, formatDistanceStrict, isEqual } from "date-fns";
    import { parse as parseMs } from "duration-fns";
    import { minuteNow, secondNow } from './stores';

    export let interval = "";
    
    $: valid = interval ? interval.split('/') : [];
    $: start = valid[0] && new Date(valid[0]); // addMinutes(new Date(), -1); //;
    $: end = valid[1] && new Date(valid[1]);//addMinutes(new Date(), 3);// 

    $: durationMs = end - start;
    $: remainingMs = end < new Date() ? 0 : start > new Date() ? durationMs : end - new Date();
    $: remainingPercent = remainingMs && (remainingMs / durationMs);
    $: remaining = parseMs(remainingMs);
    //console.log("init valid=", start, end, durationMs, remainingMs, remainingPercent, remaining);

    $: isValid = !!start && !!end && isWithinInterval(new Date(), { start, end });
    $: isExpired = end && isBefore(end, new Date());
    $: isPending = start && isAfter(start, new Date());
    $: isCancelled = isEqual(start, end);

    export let status = isCancelled ? "cancelled" : isExpired ? "expired" : isPending ? "pending" : isValid ? "current" : null;

    // recalculate based on  how much time left
    $: now = remainingMs > 0 && remainingMs <= 120000 ? secondNow : minuteNow;
    $: remainingMs = end < ($now || new Date()) ? 0 : start > ($now || new Date()) ? durationMs : end - ($now || new Date); //(expirationDate - $now) / 1000;
    $: remaining = parseMs(remainingMs);
    $: isValid = !!start && !!end && isWithinInterval($now || new Date(), { start, end });
    $: isPending = start && isAfter(start, $now || new Date());
    $: isExpired = end && isBefore(end, $now || new Date());
    $: remainingPercent = remainingMs && (remainingMs / durationMs);
    $: status = isCancelled ? "cancelled" : isExpired ? "expired" : isPending ? "pending" : isValid ? "current" : null;
    $: if(isCancelled) isValid = isExpired = isPending = false;
    //$: console.log("valid=", $now, start, end, durationMs, remainingMs, remainingPercent)
</script>

<time class="valid min max interval" datetime="{valid.join("/")}"  class:future={isPending} class:past={isExpired} class:expired={isExpired} class:pending={isPending} class:current={isValid} class:cancelled={isCancelled}>
    {#if isCancelled}
        Cancelled
    {:else}
    <time class="valid min start" datetime="{valid[0]}" class:past={isBefore(start, $now)}  class:future={isAfter(start, $now)}>
        <dfn>Start</dfn> 
        <abbr value="{start}">{start}</abbr>
        <time class="relative">{formatDistanceStrict(start, $now, {
            addSuffix:true
        })}</time>
    </time>
    <time class="valid max end" datetime="{valid[1]}" class:past={isBefore(end, $now)}  class:future={isAfter(end, $now)}>
        <dfn>End</dfn> 
        <abbr value="{end}">{end}</abbr>
        <time class="relative">{formatDistanceStrict(end, $now, {
            addSuffix:true
        })}</time>
    </time>
    {/if}
    <!-- <time class="valid elapsed" class:completed={isExpired} class:pending={isPending} datetime="PT1H">
        <figure>
            <svg class="timer" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <g class="timer track">
                    <circle class="timer elapsed" cx="50" cy="50" r="45" />
                    <path
                        stroke-dasharray="{remainingPercent * 283} 283"
                        class="remaining"
                        d="
                        M 50, 50
                        m -45, 0
                        a 45,45 0 1,0 90,0
                        a 45,45 0 1,0 -90,0
                        "
                    ></path>
                </g>
            </svg>
            <figcaption>
                {#if remaining.hours > 0}
                    {String(remaining.hours).padStart(2, '0')}:{String(remaining.minutes).padStart(2, '0')}
                {:else if remaining.minutes > 0}
                    {String(remaining.hours).padStart(2, '0')}:{String(remaining.minutes).padStart(2, '0')}
                {:else}
                    00:{String(remaining.minutes).padStart(2, '0')}
                {/if}
                </figcaption>
        </figure>
    </time> -->
</time>