<script>
  import { get, sortBy } from "lodash-es";

  import Property from "./Property.svelte";
  import Policy from "./Policy.svelte";
  import Permit from "./Permit.svelte";

  export let property = null;
  export let policies = [];
  export let permits = null;

  $: policies = sortBy(
    policies?.filter((item) => item.public !== false),
    [(policy) => policy.rank ?? 0, "name"]
  );
</script>

<article class="property">
  <header>
    <Property {property} />
  </header>

  {#if permits}
    <ul class="permits">
      {#each permits as permit}
        <li>
          <a href={!!permit && permit.id ? `/p/${permit.id}` : null}>
            <Permit {permit} />
          </a>
        </li>
      {/each}
    </ul>
  {/if}

  <nav class="policies">
    <h1>All Amenities</h1>
    <ul class="policies" data-count={get(policies, "length", "")}>
      {#if !!policies}
        {#each policies as policy}
          <li>
            <a
              href={policy.url ??
                `/properties/{policy.scope}/amenities/{policy.subject}`}
            >
              <Policy {policy} />
            </a>
          </li>
        {/each}
      {/if}
    </ul>
  </nav>
</article>
