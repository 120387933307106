<script>
  import EntryItem from "./EntryItem.svelte";

  export let entry = null;
  export let permit = null;
</script>

{#if entry?.items}
  <ul class="entry">
    {#each Object.values(entry?.items) as item}
      <li>
        <EntryItem {item} {permit} />
      </li>
    {/each}
  </ul>
{/if}
