<script>

    import { isBefore, isAfter, isWithinInterval, isEqual } from "date-fns";
    import { minuteNow, secondNow } from './stores';
    import { fetchUpdatePermitExpiration } from "./api"

    export let permit = null;

    $: valid = permit && permit.valid && permit.valid.interval ? permit.valid.interval.split('/') : [];
    $: start = valid && valid[0] && new Date(valid[0]); // addMinutes(new Date(), -1); //;
    $: end = valid && valid[1] && new Date(valid[1]);//addMinutes(new Date(), 3);//

    $: isValid = !!start && !!end && isWithinInterval($minuteNow, { start, end });
    $: isExpired = end && isBefore(end, $minuteNow);
    $: isPending = start && isAfter(start, $minuteNow);
    $: isCancelled = permit && (permit.valid.cancelled || isEqual(start, end));

    let submittable = false;
    let submitting = false;
    let started = false;

    function change(e) {
        //console.log("change", e);
        submittable = e.target.form && e.target.form.checkValidity();
    }

    async function submit(e) {

        e.preventDefault();

        if(!permit || submitting || !submittable) return;

        const form = e.target;

        const data = new FormData(form);

        submitting = true;
        const result = await fetchUpdatePermitExpiration(permit, data);
        // pull permit?
        if(!!result.message) alert(result.message);

        // clear the state
        submitting = submittable = false;

    }

</script>
{#if !isCancelled}
    {#if isPending}
        {#if started}
            <form class="cancel" on:change={change} on:input={change} on:submit={submit}>
                <fieldset>
                    <ul>
                        <li>
                            <label for="permit-cancel-auth">Passcode</label><input id="permit-cancel-auth" type="text" pattern="[0-9]*" inputmode="numeric" maxlength="6" autocorrect="off" autocomplete="off" spellcheck="false" name="token" required placeholder="required">
                        </li>
                    </ul>
                </fieldset>
                <button type="submit" disabled={!submittable || submitting}>{ submitting ? "Updating" : "Cancel Pass"}</button>
            </form>
        {:else}
            <button class="cancel" on:click={e => (started = true)}>Cancel Pass</button>
        {/if}
    {/if}
{/if}