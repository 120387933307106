import App from "./App.svelte";
import { state, end, router } from "./router";
import style from "../styles/app.scss";

const app = window.app = new App({ target: document.body, props: {} });

function view(viewname) {
    return function(ctx, next) {
        ctx.params.view = viewname;
        next();
    };
}

router("/properties/:property/*", state);
router("/properties/:property", view("property"), end);

router("/p/:permit", view("permit"), end);
router("/permits/:permit", view("permit"), end);

// policy catch all
router("/properties/:property/amenities/:policy/*", state);
router("/properties/:property/amenities/:policy/permits/new", view("newpermit"), end);

// policy home
router("/properties/:property/amenities/:policy/:subview", view("policy"), end);
router("/properties/:property/amenities/:policy", view("policy"), end);

// property subview
router(`/properties/:property/:view`, end);

// property subview
router(`/permits`, view("permits"), end);

router(`/demo`, view("demo"), end);

// index 
router(`*`, view("index"), end);

router.start();