<script>

    import UnitSelector from "./UnitSelector.svelte";
    import { fetchCreateToken } from "./api";
    import { get } from "lodash-es";

    export let property = null;
    export let units = [];
    let unitSelectorId;
    let unit = null;

    let submittable = false;
    let submitting = false;

    function change(e) {
        //console.log("change", e);
        const form = e.target.form;
        submittable = form && form.checkValidity() && form.unit && form.unit.value && form.password && form.password.value;
    }

    async function submit(e) {

        e.preventDefault();

        if(submitting || !submittable) return;

        const form = e.target;

        const data = new FormData(form);

        submitting = true;
        const result = await fetchCreateToken(form.scope.value, form.unit && form.unit.value, form.password.value);
        // pull permit?
        if(!!result.message) alert(result.message);

        // clear the state
        submitting = submittable = false;

    }

</script>
{#if property}
<form on:change={change} on:input={change} on:submit={submit}>
    <input type="hidden" name="scope" value="{property.id}" />
    <fieldset class="tenant">
        <ul>
        <li>
            <label for="{unitSelectorId}">{get(property, "tenants.title", "")}</label>
            {#if unit} 
                <input type="hidden" name="unit" value="{unit.id}" />
            {/if}
            <UnitSelector units={units} bind:id={unitSelectorId} bind:selected={unit} />
        </li>
        <li>
            <label for="permit-auth">Passcode</label><input id="permit-auth" type="text" pattern="[0-9]*" inputmode="numeric" maxlength="6" autocorrect="off" autocomplete="off" spellcheck="false" name="password" required>
        </li>
        </ul>
    </fieldset>
    <button type="submit" disabled={!submittable || submitting}>{ submitting ? "Validating" : "Continue"}</button>
</form>
{/if}