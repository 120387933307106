<script>
  import { uniqueId } from "lodash-es";

  export let units = [];
  export let selected = null;

  export const id = uniqueId("unit-select-");

  let focusClears = true;

  function onFocus(e) {
    selected = null;
    e.target.value = "";
  }

  function onUnit(e) {
    selected = units.find(
      (i) =>
        0 ===
          i.id.localeCompare(e.target.value, undefined, {
            sensitivity: "base",
          }) ||
        0 ===
          i.display.localeCompare(e.target.value, undefined, {
            sensitivity: "base",
          })
    );

    //console.log("selected=", e.target.value, selected);

    // if(!!selected) {
    //     focusClears = false;
    //     setTimeout(() => focusClears = true, 250);
    // }
    if (!!selected && e.target.matches("select")) e.target.value = "";
    if (!!selected) e.target.blur();
  }
</script>

{#if selected}
  <label for={id}>
    <data
      class="{selected.type} id {[selected.format, selected.size]
        .filter((i) => !!i)
        .join(' ')}"
      value={selected.id}>{selected.display}</data
    >
  </label>
{/if}
<input
  {id}
  type="text"
  autocomplete="off"
  autocorrect="off"
  autocapitalize="characters"
  list="{id}-units"
  spellcheck="false"
  value=""
  maxlength="30"
  on:change={onUnit}
  on:input={onUnit}
  on:focus={onFocus}
/>

<datalist id="{id}-units">
  {#each units as item}
    <option value={item.display}>{item.display}</option>
  {/each}
</datalist>
<select tabindex="-1" on:change={onUnit}>
  <!-- <option value="">----</option> -->
  {#each units as item}
    <option value={item.id}>{item.display}</option>
  {/each}
</select>
