<script>
  import { get, map } from "lodash-es";
  import { thumbnail, format } from "./util/filepreview";
  export let policy = null;
</script>

{#if policy}
  <ul class="rules">
    <li><p>{get(policy, "rules.description", "")}</p></li>
    {#if get(policy, "metered.description")}
      <li><p>{get(policy, "metered.description", "")}</p></li>
    {/if}
    {#if get(policy, "pricing.description")}
      <li><p class="pricing">{get(policy, "pricing.description", "")}</p></li>
    {/if}
    {#if get(policy, "rules.custom")}
      <li>
        <p>
          {@html get(policy, "rules.custom", "").replace(/\n|\r\n|\r/g, "<br>")}
        </p>
      </li>
    {/if}
  </ul>
  <ul class="documents" data-count={get(policy, "documents.count", "")}>
    {#each map(get(policy, "documents.items", [])) as document}
      <li>
        <a href={format(document)} target="_blank">
          <data class="document file" value={document.id}>
            <figure>
              <img alt="preview" src={thumbnail(document, 200)} />
              {#if document.title}
                <figcaption>
                  <h1>{document.title}</h1>
                </figcaption>
              {/if}
            </figure>
          </data>
        </a>
      </li>
    {/each}
  </ul>
{/if}
