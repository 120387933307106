<script>
    import { searchProperties, search } from "./stores";
    import { debounce, get } from "lodash-es";
    import page from "page";
    import Property from "./Property.svelte";

    let initValue = $search;

    const doSearch = debounce(function(query) {
        var qs = new URLSearchParams(location.search);
        if(!query) qs.delete("q");
        else qs.set("q", query);
        page(`${location.pathname}?${qs.toString()}`);
    }, 500, {
        trailing:true,
    });

    function onInput(e) {
        doSearch(e.target.value);
    }

    $:result = $searchProperties;

</script>
<form class="properties">
    <input placeholder="Find location by name" autocomplete="off" type="search" name="q" on:input={onInput} on:change={onInput} on:submit={onInput} value="{initValue || ""}" />
</form>
{#if result}
    {#if result.loading}
        <ul class="properties" data-count=""></ul>
    {:else}
        <ul class="properties" data-count="{get(result.items, "length", "")}">
            {#each result.items as property}
                <li>
                    <a href="/properties/{property.id}">
                        <Property property={property} />
                    </a>
                </li>
            {/each}
        </ul>
    {/if}
{/if}