<script>
  import { chunk } from "lodash-es";

  export let item = null;
  $: keycode = item?.enabled && [item.code].filter((i) => null != i).join("");

  function chunks(str) {
    if (str.length < 5) return [str];

    switch (str.length) {
      case 5:
      case 6:
        return [keycode.substring(0, 3), keycode.substring(3)];
      case 7:
      case 8:
        return [keycode.substring(0, 4), keycode.substring(4)];
      case 9:
      case 10:
        return [
          keycode.substring(0, 3),
          keycode.substring(3, 6),
          keycode.substring(6),
        ];
    }

    return chunk([...str], 4);
  }
</script>

{#if keycode}
  <data value={keycode} class="keypad">
    {#if null != item.pre}
      <span>
        {#each item.pre as char}
          <data class="key" value={char}>{char}</data>{/each}
      </span>
      <span />{/if}
    {#each chunks(keycode) as group}
      <span>
        {#each group as char}
          <data class="key" value={char}>{char}</data>{/each}</span
      >
      <span />{/each}
    {#if null != item.post}
      <span>
        {#each item.post as char}
          <data class="key" value={char}>{char}</data>{/each}
      </span>
    {/if}</data
  >
{/if}
