<script>

    export let property = null;

</script>

{#if property && property.id}
<data class="{property.type}" value="{property.id}">
    <figure>
        {#if property.photo}
        <img alt="property photo" src="{property.photo.url.replace("https://upload.parkingboss.com/files/", "https://amenitypass.imgix.net/")}?auto=compress,format&fit=crop&h=250&w=500&crop=focalpoint"/>
        {/if}
        <!-- <img alt="property map" src="{[
                "https://api.mapbox.com/styles/v1/mapbox/navigation-guidance-day-v4/static/",
                "geojson(",
                encodeURIComponent(JSON.stringify({
                    type:"Feature",
                    geometry:property.address.area,
                    properties:{
                    //     "stroke": "%23222",
                    //     "stroke-opacity": 0.5,
                    //     "stroke-width": 2,
                    //     "fill": "%23222",
                    //     "fill-opacity": 0.05
                    }
                })),
                ")/",
                //marker,
                property.address.longitude,
                ",",
                property.address.latitude,
                ",14,0,0/",
                500,
                "x",
                250,
                (window.devicePixelRatio > 1.5 ? "@2x" : ""),
                "?logo=false&attribution=false&access_token=pk.eyJ1IjoibGVtZXN1cmllciIsImEiOiJWcEhvYVNjIn0.aSEzIDJPY_ujnCkPDgS9Aw"
            ].join("")}"/> -->
    </figure>
    <h1>{property.name}</h1>
    <address>
        <span class="street">{property.address.street}</span>
        <span class="city">{property.address.city}</span>
        <span class="state">{property.address.state}</span>
        <span class="postal">{property.address.postal}</span>
    </address>
    {#if property.contact}
    <aside class="contact">
        {property.contact.name || "management office"}
        <address>
            {#if property.contact.email}
            <a href="mailto:{property.contact.email}">{property.contact.email}</a><br>
            {/if}
            {#if property.contact.tel}
            <a href="tel:+{property.contact.tel}">{property.contact.tel}</a>
            {/if}
        </address>
    </aside>
    {/if}  
</data>
{/if}